@charset "UTF-8";
/* 薄いグレー */
/* 濃い茶 */
/* 黄緑 */
/* ベージュ */
/* 明るい黄緑 */
/* 濃い緑 */
/* smartphone font-size */
/* media quary */
/* flex */
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import url("https://fonts.googleapis.com/css?family=Muli");
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #333;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  overflow-x: hidden;
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  body {
    width: 1024px;
  }
}

a {
  color: #333;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  transition: .2s;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover img {
  opacity: 0.9;
}

.text {
  line-height: 1.5;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clear {
  clear: both;
}

/* show only  SP/PC
-------------------------------*/
@media screen and (min-width: 960px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .sp-s {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .pc-s {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc-s {
    display: none !important;
  }
}

/* @group float
-------------------------------- */
.right {
  float: right;
}

.left {
  float: left;
}

.img-left {
  float: left;
  margin: 0 20px 10px 0;
}

.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @text-align
-------------------------------- */
.ta-right {
  text-align: right !important;
}

.ta-center {
  text-align: center !important;
}

.ta-left {
  text-align: left !important;
}

/* @vertical-align
-------------------------------- */
.va-top {
  vertical-align: top !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.va-middle {
  vertical-align: middle !important;
}

/* @group color
-------------------------------- */
.red {
  color: #EA5413 !important;
}

.blue {
  color: #0099ff !important;
}

.gray {
  color: #666 !important;
}

.pink {
  color: #C60070 !important;
}

.peach {
  color: #F86363 !important;
}

.green {
  color: #89B929 !important;
}

/* @group margin
-------------------------------- */
.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml40 {
  margin-left: 40px !important;
}

/* @font size
-------------------------------- */
.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs19 {
  font-size: 19px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs21 {
  font-size: 21px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs23 {
  font-size: 23px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs25 {
  font-size: 25px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs27 {
  font-size: 27px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs29 {
  font-size: 29px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs31 {
  font-size: 31px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs34 {
  font-size: 34px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs38 {
  font-size: 38px !important;
}

.fs48 {
  font-size: 48px !important;
}

.fs56 {
  font-size: 56px !important;
}

.fs64 {
  font-size: 64px !important;
}

/* @font-weight
-------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

/* @border
-------------------------------- */
.bb-none {
  border-bottom: 0 !important;
}

.bl-none {
  border-left: 0 !important;
}

.br-none {
  border-right: 0 !important;
}

.bt-none {
  border-top: 0 !important;
}

/* @status font color
-------------------------------- */
.required {
  color: #c00;
}

.error-message {
  color: #c00;
}

/* @wp image
-------------------------------- */
.alignleft {
  float: left;
  margin: 0 20px 10px 0;
}

.alignright {
  float: right;
  margin: 0 0 10px 20px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* @flex
-------------------------------- */
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .flex {
    display: block;
    width: auto;
  }
}

.flex-sp {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* @font-family
-------------------------------- */
.mincho {
  font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.yu {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.contents {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 50px;
}

@media screen and (max-width: 599px) {
  .contents {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .contents {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  .contents {
    margin-top: 30px;
  }
}

.main {
  width: 680px;
  line-height: 1.5;
  font-size: 15px;
}

@media screen and (max-width: 599px) {
  .main {
    width: auto;
    margin-bottom: 50px;
  }
}

.side {
  width: 280px;
}

@media screen and (max-width: 599px) {
  .side {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.base-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.base-wrap--space {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap--space {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.main-wrap {
  width: 680px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .main-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.main-wrap--space {
  width: 680px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .main-wrap--space {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.screen-reader-text {
  display: none;
}

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/
.header {
  margin-bottom: 20px;
}

@media screen and (max-width: 599px) {
  .header {
    height: auto;
    margin-bottom: 20px;
  }
}

.header__copy {
  margin: 5px 0;
  font-size: 12px;
}

@media screen and (max-width: 599px) {
  .header__copy {
    display: none;
  }
}

.header__logo {
  float: left;
  display: inline-block;
  padding: 10px 0 0 0;
}

@media screen and (max-width: 599px) {
  .header__logo {
    float: none;
    display: block;
    padding: 15px 0 0;
    text-align: center;
  }
}

@media screen and (max-width: 599px) {
  .header__logo__title {
    float: none;
    margin-left: 10px;
    text-align: left;
  }
}

.header__logo__title a img {
  width: 330px;
  height: 70px;
}

@media screen and (max-width: 599px) {
  .header__logo__title a img {
    width: 280px;
    height: 50px;
  }
}

.header__info {
  float: right;
  display: inline-block;
  padding: 12px 0 0 0;
}

@media screen and (max-width: 599px) {
  .header__info {
    display: none;
  }
}

.header__address {
  display: inline-block;
  margin-right: 40px;
  padding-top: 4px;
  color: #666666;
  font-size: 13px;
  vertical-align: middle;
}

.header__contact {
  display: inline-block;
  vertical-align: middle;
}

.header__mail a {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #9AAA27;
  background: #9AAA27 url(../img/header/icn_mail_w.svg) 6px center no-repeat;
  background-size: 24px 12px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-indent: 1em;
}

.header__mail a:hover {
  background: #D6DF20 url(../img/header/icn_mail_b.svg) 6px center no-repeat;
  background-size: 24px 12px;
  color: #251E0D;
}

.header__tel {
  display: block;
  padding-left: 26px;
  background: url(../img/header/icn_tel.svg) left no-repeat;
  background-size: 22px 16px;
  color: #251E0D;
  font-size: 20px;
}

#FixNavigator {
  background: #9AAA27;
}

.nav-global {
  z-index: 1000;
}

@media screen and (max-width: 599px) {
  .nav-global {
    position: static;
    display: none;
    width: auto;
  }
}

.nav-global__list {
  display: table;
  width: 100%;
}

@media screen and (max-width: 599px) {
  .nav-global__list {
    display: block;
    height: auto;
    border-left: none;
    border-top: 1px solid #999;
  }
}

.nav-global__list__item {
  position: relative;
  display: table-cell;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item {
    display: block;
    border-bottom: 1px solid #fff;
  }
}

.nav-global__list__item > a {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  padding: 0 30px;
  border-left: 1px solid #D6DF20;
  color: #fff;
  font-size: 17px;
  text-align: center;
  line-height: 60px;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item > a {
    width: auto;
    height: auto;
    padding: 15px 20px;
    border-right: none;
    border-bottom: none;
    background: #9AAA27 url(../img/sp/icn_arrow_01.png) 95% center no-repeat;
    background-size: 6px 12px;
    color: #fff;
    line-height: 1.5;
    text-align: left;
  }
}

.nav-global__list__item > a:hover {
  background: #D6DF20;
  color: #251E0D;
}

.nav-global__list__item > a:active {
  background: #D6DF20;
  color: #251E0D;
}

.nav-global__list__item:last-child a {
  padding: auto 38px;
  border-right: 1px solid #D6DF20;
}

@media screen and (min-width: 960px) {
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
  }
  .fixed .mb60 {
    margin-bottom: 0 !important;
  }
}

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
/* Footer
-------------------- */
.footer__info {
  padding: 25px 0 30px;
  background: #F4EEE9;
}

.footer__info__logo {
  float: left;
}

@media screen and (max-width: 599px) {
  .footer__info__logo {
    float: none;
    text-align: center;
  }
}

.footer__info__logo img {
  width: 300px;
  height: 70px;
}

.footer__info__sign {
  float: right;
  display: inline-block;
}

@media screen and (max-width: 599px) {
  .footer__info__sign {
    float: none;
    display: block;
  }
}

.footer__info__sign__address {
  display: inline-block;
  margin-right: 30px;
  padding-top: 4px;
  color: #666666;
  font-size: 13px;
}

@media screen and (max-width: 599px) {
  .footer__info__sign__address {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
}

.footer__info__sign__contact {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 599px) {
  .footer__info__sign__contact {
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 599px) {
  .footer__info__sign__mail {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
}

.footer__info__sign__mail a {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #9AAA27;
  background: #9AAA27 url(../img/header/icn_mail_w.svg) 6px center no-repeat;
  background-size: 24px 12px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-indent: 1em;
}

.footer__info__sign__mail a:hover {
  background: #D6DF20 url(../img/header/icn_mail_b.svg) 6px center no-repeat;
  background-size: 24px 12px;
  color: #251E0D;
}

.footer__info__sign__tel {
  display: block;
  padding-left: 26px;
  background: url(../img/header/icn_tel.svg) left no-repeat;
  background-size: 22px 16px;
  color: #251E0D;
  font-size: 20px;
}

@media screen and (max-width: 599px) {
  .footer__info__sign__tel {
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
    vertical-align: middle;
  }
}

.footer__line {
  padding: 20px 0;
  background: #9AAA27;
  font-size: 11px;
}

.copyright {
  color: #fff;
  text-align: center;
}

.nav-footer {
  padding: 50px 0;
  border-top: 10px solid #efefef;
}

.nav-footer__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .nav-footer__inner {
    display: block;
    width: auto;
  }
}

.nav-footer__title {
  margin-bottom: 10px;
  font-size: 17px;
}

@media screen and (max-width: 599px) {
  .nav-footer__list {
    margin-bottom: 20px;
  }
}

.nav-footer__list__item {
  line-height: 1.5;
}

.nav-footer__list__item::before {
  content: "・";
}

.nav-footer__list__item a {
  text-decoration: none;
}

.nav-footer__list__item a:hover {
  text-decoration: underline;
}

.nav-footer__01 {
  width: 36%;
  padding-right: 2%;
}

@media screen and (max-width: 599px) {
  .nav-footer__01 {
    width: auto;
    padding-right: 0;
  }
}

.nav-footer__02 {
  width: 28%;
  padding-right: 2%;
}

@media screen and (max-width: 599px) {
  .nav-footer__02 {
    width: auto;
    padding-right: 0;
    padding-right: 0;
  }
}

.nav-footer__03 {
  width: 16%;
  padding-right: 2%;
}

@media screen and (max-width: 599px) {
  .nav-footer__03 {
    width: auto;
    padding-right: 0;
  }
}

.nav-footer__04 {
  width: 20%;
}

@media screen and (max-width: 599px) {
  .nav-footer__04 {
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  .posts-footer {
    margin-bottom: 0;
  }
}

.layout-form {
  padding: 30px 0 10px;
  background: #ededed;
}

.layout-form__inner {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .layout-form__inner {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.layout-form .form-description {
  margin-bottom: 20px;
}

.layout-form table {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid #251E0D;
}

.layout-form table tr {
  border-bottom: 1px solid #251E0D;
}

.layout-form table th {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  color: #251E0D;
  font-size: 16px;
  text-align: left;
}

@media screen and (max-width: 599px) {
  .layout-form table th {
    font-size: 16px;
    font-size: 4.26667vw;
    display: block;
    width: 100%;
    padding: 15px 10px 0 10px;
  }
}

.layout-form table td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  font-size: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .layout-form table td {
    font-size: 15px;
    font-size: 4vw;
    display: block;
    width: 100%;
    padding: 10px 10px 15px;
  }
}

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/
/* サイドバー
-------------------- */
/* タイトル */
.side-title {
  position: relative;
  padding: 20px 30px;
  border-top: 2px solid #9AAA27;
  border-bottom: 1px solid #E3E7ED;
  background: #F4EEE9;
  color: #251E0D;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
}

.side-title:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  content: '';
  height: 0;
  width: 100%;
  border-top: 2px solid #fff;
}

.side-subtitle {
  padding: 10px;
  background: #251E0D;
  color: #fff;
}

/* コンテンツ */
.side-box {
  margin-bottom: 30px;
  border-left: 1px solid #E3E7ED;
  border-right: 1px solid #E3E7ED;
  border-bottom: 1px solid #E3E7ED;
}

.side-about {
  padding: 15px;
  line-height: 1.4;
}

.side-about__title {
  margin-bottom: 10px;
}

.side-about__title span {
  color: #565B17;
  font-size: 18px;
  font-weight: bold;
}

.side-about__address {
  margin-bottom: 6px;
}

.side-about__tel {
  color: #565B17;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.side-about__mail {
  margin-bottom: 6px;
  font-family: 'Montserrat', sans-serif;
}

.side-about__about {
  margin-bottom: 20px;
}

.side-about__about a {
  color: #565B17;
  text-decoration: underline;
}

.side-about__contact {
  text-align: center;
}

.side-about__contact a {
  display: block;
  padding: 5px 20px;
}

.side-list {
  margin-bottom: 30px;
  padding: 10px 20px;
  border-left: 1px solid #E3E7ED;
  border-right: 1px solid #E3E7ED;
  border-bottom: 1px solid #E3E7ED;
}

.side-list__item a {
  position: relative;
  display: block;
  padding: 8px 0 8px 16px;
}

.side-list__item a::before {
  position: absolute;
  top: 9px;
  left: 0;
  display: block;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-left: 8px solid #9AAA27;
}

.side-list--2col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.side-list--2col li {
  width: 50%;
}

@media screen and (max-width: 599px) {
  .side-list--2col li {
    width: 33%;
  }
}

/* サイドバナー */
.side-banner {
  margin: 10px 0 30px;
  text-align: center;
}

.side-banner li {
  margin: 0 0 10px;
}

/* ローカルナビ */
.widget {
  margin-bottom: 50px;
}

.widget-local-navi > ul {
  margin: 0 -2px;
  padding: 0 2px 15px;
}

.widget-local-navi > ul > li {
  border-bottom: 1px dotted #CCCCCC;
}

.widget-local-navi li a {
  display: block;
  padding: 16px 10px 16px 16px;
  text-decoration: none;
  color: #333;
}

.widget-local-navi li a:hover {
  color: #CC3300;
}

.widget-local-navi li:last-child {
  border-bottom: none;
}

/* ローカルナビ2層 */
.widget-local-navi ul ul {
  margin-left: 16px;
  margin-bottom: 10px;
}

.widget-local-navi ul ul li a {
  padding: 0px 10px 5px 20px;
  font-size: 13px;
}

.blog-widget li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
  line-height: 1.2;
}

.blog-widget li a {
  color: #1D4C86;
  text-decoration: none;
}

.blog-widget li a:hover {
  color: #00A0D9;
  text-decoration: underline;
}

/*--------------------------------------------------------
  スマホナビゲーション
----------------------------------------------------------*/
/* スマホグローバルナビ開閉ボタン */
@media screen and (max-width: 599px) {
  .nav-sp {
    display: none;
  }
}

.header__menu {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 50px;
}

@media screen and (max-width: 599px) {
  .header__menu {
    display: block;
  }
}

#panel-btn {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background: #9AAA27;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#panel-btn .close {
  background: transparent;
}

#panel-btn .close::before, #panel-btn .close::after {
  margin-top: 0;
}

#panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 4px;
  margin: -2px 0 0 -15px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

#panel-btn-icon::before, #panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 4px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

#panel-btn-icon::before {
  margin-top: -12px;
}

#panel-btn-icon::after {
  margin-top: 8px;
}

#BtnClose {
  display: none;
  padding: 20px 0 20px;
  background: #efefef;
  color: #9AAA27;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  font-size: 20px;
  font-size: 5.33333vw;
}

@media screen and (max-width: 599px) {
  #BtnClose {
    display: block;
  }
}

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/
/* ボタンスタイル */
.button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 2px;
  background: #9AAA27;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  text-decoration: none;
}

.button:hover {
  color: #251E0D;
}

.button:active {
  position: relative;
  top: 1px;
}

/* フォーム送信完了ボタン */
.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 150px;
  padding: 15px 20px;
  border: none;
  background: #9AAA27;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.btn:hover {
  color: #251E0D;
}

.btn:active {
  color: #251E0D;
}

.btn:visited {
  color: #fff;
}

/* エラーページボタン */
.link a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 150px;
  padding: 15px 20px;
  border: none;
  background: #9AAA27;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し */
/* <span>で囲む */
.title01 {
  position: relative;
  margin-bottom: 40px;
  padding-left: 30px;
  padding: 1px 0 1px 30px !important;
  border-top: 1px solid #E3E7ED;
  border-bottom: 1px solid #E3E7ED;
  font-family: "M PLUS Rounded 1c";
  font-size: 20px;
  line-height: 65px;
}

.title01::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  height: 30px;
  width: 4px;
  margin-top: -15px;
  background: #9AAA27;
}

.title01 span {
  position: relative;
  display: block;
}

.title01 span::before {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  content: "";
  width: 150px;
  height: 65px;
  background: #F4EEE9;
}

.title01 span::after {
  position: absolute;
  right: 150px;
  top: 0;
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 65px 65px;
  border-color: transparent transparent #F4EEE9 transparent;
}

.title01 a {
  text-decoration: none;
}

.title01 a:hover {
  text-decoration: underline;
}

.title02 {
  position: relative;
  margin: 40px 0 20px;
  padding-left: 20px;
}

.title02::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  height: 16px;
  width: 12px;
  margin-top: -6px;
  background: #251E0D;
}

.title03 {
  margin: 30px 0 10px;
  font-weight: bold;
}

.title03.list-style {
  display: list-item;
  list-style-type: square;
  list-style-position: inside;
}

/* コンテンツ内見出し */
.main h1 {
  padding: 20px 0 20px 30px;
  color: #251E0D;
  font-size: 40px;
  font-family: "M PLUS Rounded 1c";
  font-weight: 100;
  line-height: 100px;
}

@media screen and (max-width: 599px) {
  .main h1 {
    padding: 10px 0 10px 20px;
    font-size: 20px;
    font-weight: normal;
    line-height: 60px;
  }
}

.main h2 {
  position: relative;
  margin-bottom: 40px;
  padding-left: 30px;
  padding: 10px 0 10px 30px;
  border-top: 1px solid #E3E7ED;
  border-bottom: 1px solid #E3E7ED;
  font-family: "M PLUS Rounded 1c";
  font-size: 20px;
}

@media screen and (max-width: 599px) {
  .main h2 {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.main h2::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  height: 30px;
  width: 4px;
  margin-top: -15px;
  background: #9AAA27;
}

.main h2 .inner {
  position: relative;
  left: -30px;
  display: block;
  padding-left: 30px;
  border-left: 4px solid #9AAA27;
}

.main h2 a {
  text-decoration: none;
}

.main h2 a:hover {
  text-decoration: underline;
}

.main h3 {
  position: relative;
  margin: 40px 0 20px;
  padding-left: 40px;
  font-size: 18px;
}

@media screen and (max-width: 599px) {
  .main h3 {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.main h3::before {
  position: absolute;
  top: 10px;
  left: 0;
  display: block;
  content: '';
  height: 4px;
  width: 30px;
  background: #9AAA27;
}

.main h4, .main h5, .main h6 {
  margin: 30px 0 10px;
  font-weight: bold;
}

.main h4.list-style, .main h5.list-style, .main h6.list-style {
  display: list-item;
  list-style-type: square;
  list-style-position: inside;
}

/*--------------------------------------------------------
  ぱんくず
----------------------------------------------------------*/
.breadcrumbs {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin: 20px auto 50px;
  color: #333;
  font-size: 11px;
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    width: auto;
    margin: 10px 10px 20px;
  }
}

.breadcrumbs a {
  display: inline-block;
  margin: 0 5px;
  color: #333;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .separator01 {
  color: #000;
}

.breadcrumbs strong {
  display: inline-block;
  margin: 0 5px;
  font-weight: normal;
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    display: none;
  }
}

/*--------------------------------------------------------
  ページネーション
----------------------------------------------------------*/
.pagination {
  margin: 60px 0;
  text-align: center;
  font-size: 14px;
}

.pagination span.disabled,
.pagination span.prev a,
.pagination span.next a {
  margin: 0 20px;
  color: #251E0D;
}

.pagination span.current,
.pagination span.number a {
  margin: 0 4px;
  padding: 7px 12px;
  background: #dedede;
  color: #fff;
  text-decoration: none !important;
}

.pagination span.current,
.pagination span.number a:hover {
  background: #251E0D;
}

.pagination a:active {
  background: #251E0D;
}

/*--------------------------------------------------------
  コンテンツナビ
----------------------------------------------------------*/
.contents-navi {
  margin: 50px 0;
  text-align: center;
}

.contents-navi a {
  display: inline-block;
  padding: 11px 14px;
  color: #000;
}

.contents-navi a.prev-link, .contents-navi a.next-link {
  background: #ddd;
}

.contents-navi a:hover {
  color: #333;
}

.contents-navi a:hover.prev-link, .contents-navi a:hover.next-link {
  background: #eee;
}

/*--------------------------------------------------------
  ページ上部へ戻るボタン
----------------------------------------------------------*/
.page-top {
  background: #999999;
  text-align: center;
  cursor: pointer;
}

/*--------------------------------------------------------
  テーブル
----------------------------------------------------------*/
/* コンテンツ内テーブル */
.main table {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid #251E0D;
}

.main table tr {
  border-bottom: 1px solid #251E0D;
}

.main table th {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  color: #251E0D;
  font-size: 16px;
  text-align: left;
}

@media screen and (max-width: 599px) {
  .main table th {
    font-size: 16px;
    font-size: 4.26667vw;
    display: block;
    width: 100%;
    padding: 15px 10px 0 10px;
  }
}

.main table td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  font-size: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .main table td {
    font-size: 15px;
    font-size: 4vw;
    display: block;
    width: 100%;
    padding: 10px 10px 15px;
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
}

.table-scroll::-webkit-scrollbar {
  height: 5px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

@media screen and (max-width: 599px) {
  .table-scroll th {
    font-size: 16px;
    font-size: 4.26667vw;
    display: table-cell !important;
    width: auto !important;
    padding: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  .table-scroll td {
    font-size: 15px;
    font-size: 4vw;
    display: table-cell !important;
    width: auto !important;
    padding: 10px !important;
  }
}

/*--------------------------------------------------------
	icon
----------------------------------------------------------*/
/*--------------------------------------------------------
	リスト
----------------------------------------------------------*/
.main--default ul {
  margin: 10px 0 10px 1em;
}

.main--default ul > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 16px;
}

.main--default ul > li::before {
  position: absolute;
  top: 6px;
  left: 0;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #9AAA27;
}

.main--default ul > li ul li {
  padding: 0;
}

.main--default ul > li ul li::before {
  display: none;
}

.main--default ol {
  margin: 10px 0 30px 1em;
  list-style: none;
}

.main--default ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 22px;
}

.main--default ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #565B17;
}

.main--default ol > li:nth-child(1)::before {
  content: "1. ";
}

.main--default ol > li:nth-child(2)::before {
  content: "2. ";
}

.main--default ol > li:nth-child(3)::before {
  content: "3. ";
}

.main--default ol > li:nth-child(4)::before {
  content: "4. ";
}

.main--default ol > li:nth-child(5)::before {
  content: "5. ";
}

.main--default ol > li:nth-child(6)::before {
  content: "6. ";
}

.main--default ol > li:nth-child(7)::before {
  content: "7. ";
}

.main--default ol > li:nth-child(8)::before {
  content: "8. ";
}

.main--default ol > li:nth-child(9)::before {
  content: "9. ";
}

.main--default ol > li > ol {
  margin-left: 0;
}

.main--default ol > li > ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 26px;
}

.main--default ol > li > ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #565B17;
}

.main--default ol > li > ol > li:nth-child(1)::before {
  content: "(1)";
}

.main--default ol > li > ol > li:nth-child(2)::before {
  content: "(2)";
}

.main--default ol > li > ol > li:nth-child(3)::before {
  content: "(3)";
}

.main--default ol > li > ol > li:nth-child(4)::before {
  content: "(4)";
}

.main--default ol > li > ol > li:nth-child(5)::before {
  content: "(5)";
}

.main--default ol > li > ol > li:nth-child(6)::before {
  content: "(6)";
}

.main--default ol > li > ol > li:nth-child(7)::before {
  content: "(7)";
}

.main--default ol > li > ol > li:nth-child(8)::before {
  content: "(8)";
}

.main--default ol > li > ol > li:nth-child(9)::before {
  content: "(9)";
}

.main--default ol > li > ol > li > ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 22px;
}

.main--default ol > li > ol > li > ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #565B17;
}

.main--default ol > li > ol > li > ol > li:nth-child(1)::before {
  content: "①";
}

.main--default ol > li > ol > li > ol > li:nth-child(2)::before {
  content: "②";
}

.main--default ol > li > ol > li > ol > li:nth-child(3)::before {
  content: "③";
}

.main--default ol > li > ol > li > ol > li:nth-child(4)::before {
  content: "④";
}

.main--default ol > li > ol > li > ol > li:nth-child(5)::before {
  content: "⑤";
}

.main--default ol > li > ol > li > ol > li:nth-child(6)::before {
  content: "⑥";
}

.main--default ol > li > ol > li > ol > li:nth-child(7)::before {
  content: "⑦";
}

.main--default ol > li > ol > li > ol > li:nth-child(8)::before {
  content: "⑧";
}

.main--default ol > li > ol > li > ol > li:nth-child(9)::before {
  content: "⑨";
}

.wysiwyg {
  margin-bottom: 50px;
}

.wysiwyg ul {
  margin-left: 1em;
}

.wysiwyg ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 30px;
  color: #565B17;
  font-size: 16px;
  font-weight: bold;
}

.wysiwyg ul li::before {
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  display: block;
  width: 20px;
  height: 14px;
  background: url(/theme/bccompany/img/common/icn_check.svg) no-repeat;
  background-size: 20px 14px;
}

/*--------------------------------------------------------
  フォーム
----------------------------------------------------------*/
@media screen and (max-width: 599px) {
  .form-table tr {
    margin-bottom: 1.5em;
  }
}

.form-table tr th {
  width: 30%;
  vertical-align: top;
}

@media screen and (max-width: 599px) {
  .form-table tr th {
    display: block;
    width: auto;
    padding: 5px 10px;
    background: #F4EEE9;
    color: #251E0D;
  }
}

@media screen and (max-width: 599px) {
  .form-table tr td {
    display: block;
    width: auto;
  }
}

.form-table input[type=text] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 10px 4px 0;
  padding: 8px;
  border: 1px solid #9AAA27;
  font-size: 16px;
}

.form-table .checkbox {
  font-size: 16px;
}

.form-table textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border: 1px solid #9AAA27;
  font-size: 16px;
}

.form-table select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 0 0 4px 0;
  border: 1px solid #9AAA27;
  font-size: 16px;
}

.form-table a {
  color: #565B17;
  text-decoration: underline;
}

.form-table ul li {
  padding-left: 0;
}

.form-table ul li::before {
  display: none;
}

.form-s {
  width: 24% !important;
}

.form-m {
  width: 50% !important;
}

.form-l {
  width: 90%;
}

@media screen and (max-width: 599px) {
  .form-l {
    width: 100%;
  }
}

.required {
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 5px;
  padding: 1px 4px;
  border-radius: 4px;
  background: #c00;
  color: #fff;
  font-size: 12px;
}

.submit {
  margin: 30px 0;
  text-align: center;
}

.submit .form-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 150px;
  padding: 15px 20px;
  border: none;
  background: #9AAA27;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.mail-description {
  margin-bottom: 10px;
}

.mail-before-attachment {
  margin-right: 10px;
}

.mail-after-attachment {
  margin-left: 10px;
  margin-right: 10px;
}

#flashMessage {
  margin-bottom: 10px;
}

.alert-message {
  color: #c00;
}

#FieldMessageZip {
  margin-right: 15px;
}

.form-back {
  margin-top: 50px;
}

/*--------------------------------------------------------
  メインイメージ
----------------------------------------------------------*/
/* mainimage */
.mainimage {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .mainimage {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.mainimage__wrap {
  height: 450px;
  overflow: hidden;
}

@media screen and (max-width: 599px) {
  .mainimage__wrap {
    height: auto;
  }
}

@media screen and (max-width: 599px) {
  .bx-prev, .bx-next {
    display: none;
  }
  .bx-wrapper {
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/
/* MainImage
-------------------- */
.pc-mainimage {
  position: relative;
  width: 100%;
  min-width: 1000px;
  height: 400px;
  margin-bottom: 10px;
  background: #F4EEE9;
}

@media screen and (max-width: 599px) {
  .pc-mainimage {
    display: none;
  }
}

.pc-mainimage__image {
  position: relative;
  left: 50%;
  top: 0;
  width: 1000px;
  margin-left: -500px;
}

.sp-mainimage {
  display: none;
}

@media screen and (max-width: 599px) {
  .sp-mainimage {
    display: block;
    margin-bottom: 10px;
  }
  .sp-mainimage img {
    width: 100%;
  }
}

.mainimage__note {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

@media screen and (max-width: 599px) {
  .mainimage__note {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.mainimage__note__text {
  line-height: 1.5;
}

/* Main
-------------------- */
.feed-news {
  margin-bottom: 70px;
}

.feed-news .title-header {
  position: relative;
}

.feed-news .title-header .link-more {
  position: absolute;
  top: 2px;
  right: 0;
}

.feed-news .title-header .link-more a {
  position: relative;
  display: block;
  padding: 0 25px;
  color: #251E0D;
  text-decoration: none;
  line-height: 65px;
}

.feed-news .title-header .link-more a::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  margin-top: -10px;
  border-radius: 50%;
  background: #9AAA27;
}

.feed-news .title-header .link-more a::after {
  position: absolute;
  top: 50%;
  left: 6px;
  display: block;
  content: '';
  width: 5px;
  height: 5px;
  margin-top: -3px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: rotate(45deg);
}

.feed-news ul li {
  padding: 15px 0;
  border-bottom: 1px dashed #999999;
}

.feed-news ul li:before, .feed-news ul li:after {
  content: "";
  display: table;
}

.feed-news ul li:after {
  clear: both;
}

.feed-news ul li .date {
  float: left;
  display: block;
  color: #999999;
}

@media screen and (max-width: 599px) {
  .feed-news ul li .date {
    float: none;
  }
}

.feed-news ul li .title {
  display: block;
  margin-left: 7em;
  font-size: 15px;
}

@media screen and (max-width: 599px) {
  .feed-news ul li .title {
    margin-left: 0;
  }
}

.feed-news ul li .title a {
  text-decoration: none;
}

.feed-news ul li .title a:hover {
  text-decoration: underline;
}

.feed-news ul li .title--default {
  margin-left: 0;
}

.service-price span {
  margin: 0 3px 0 10px;
  color: #f00;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: bold;
}

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/
/* トップタイトル
-------------------- */
.page-mv {
  background: #F4EEE9;
}

.page-mv__title {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0 20px 30px;
  color: #251E0D;
  font-size: 40px;
  font-family: "M PLUS Rounded 1c";
  font-weight: 100;
  line-height: 100px;
}

@media screen and (max-width: 599px) {
  .page-mv__title {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .page-mv__title {
    padding: 10px 0 10px 20px;
    font-size: 20px;
    font-weight: normal;
    line-height: 60px;
  }
}

/* 汎用スタイル
-------------------- */
/* 文章 */
.txt-copy {
  font-size: 24px;
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (max-width: 599px) {
  .main {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.main p {
  margin-bottom: 30px;
}

.main .box01 {
  margin-bottom: 30px;
}

.main .box01 p {
  margin-bottom: 10px;
}

/* 会社概要Googleマップ */
#map {
  max-width: 100%;
}

/* ブログシステム */
.blog__box {
  margin-bottom: 50px;
}

.blog__box .date {
  position: relative;
  top: -1px;
  margin-left: 10px;
  color: #888;
  font-size: 14px;
}

.blog__item .date {
  position: relative;
  top: 3px;
  display: block;
  float: left;
  margin-right: 10px;
  color: #888;
  font-size: 14px;
}

.blog__item .title {
  display: block;
  margin-left: 6em;
}

.blog__body {
  margin-bottom: 20px;
}

.blog__more {
  text-align: right;
}

.blog #post-detail {
  margin: 50px 0;
}

/* 料金表 */
.price__table {
  margin-bottom: 30px;
}

.price__table table {
  border: none;
}

.price__table table tr {
  border: none;
}

.price__table table td {
  padding: 20px 10px;
  border: 1px solid #ddd;
}

.price__table table td:nth-child(1) {
  width: 40%;
}

@media screen and (max-width: 599px) {
  .price__table table td:nth-child(1) {
    width: auto;
    border-bottom: none;
  }
}

.price__table table td:nth-child(2) {
  width: 40%;
}

@media screen and (max-width: 599px) {
  .price__table table td:nth-child(2) {
    width: auto;
    border-bottom: none;
  }
}

.price__table table td:nth-child(3) {
  width: 20%;
}

@media screen and (max-width: 599px) {
  .price__table table td:nth-child(3) {
    width: auto;
  }
}

.price__table table td p {
  margin-bottom: 0;
}
