@charset "utf-8";

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し */
/* <span>で囲む */
.title01 {
  position: relative;
  margin-bottom: 40px;
  padding-left: 30px;
  padding: 1px 0 1px 30px !important;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  font-family: $font-round;
  font-size: 20px;
  line-height: 65px;
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    content: '';
    height: 30px;
    width: 4px;
    margin-top: -15px;
    background: $sub;
  }
  span {
    position: relative;
    display: block;
    &::before {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      content: "";
      width: 150px;
      height: 65px;
      background: $light-main;
    }
    &::after {
      position: absolute;
      right: 150px;
      top: 0;
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 65px 65px;
      border-color: transparent transparent $light-main transparent;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.title02 {
  position: relative;
  margin: 40px 0 20px;
  padding-left: 20px;
  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    content: '';
    height: 16px;
    width: 12px;
    margin-top: -6px;
    background: $main;
  }
}
.title03 {
  margin: 30px 0 10px;
  font-weight: bold;
  &.list-style {
    display: list-item;
    list-style-type: square;
    list-style-position: inside;
  }
}

/* コンテンツ内見出し */
.main {
  h1 {
    padding: 20px 0 20px 30px;
    color: $main;
    font-size: 40px;
    font-family: $font-round;
    font-weight: 100;
    line-height: 100px;
    @include mq(sp) {
      padding: 10px 0 10px 20px;
      font-size: 20px;
      font-weight: normal;
      line-height: 60px;
    }
  }
  h2 {
    position: relative;
    margin-bottom: 40px;
    padding-left: 30px;
    padding: 10px 0 10px 30px;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    font-family: $font-round;
    font-size: 20px;
    @include mq(sp) {
      @include fz_vw(16);
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      content: '';
      height: 30px;
      width: 4px;
      margin-top: -15px;
      background: $sub;
    }
    .inner {
      position: relative;
      left: -30px;
      display: block;
      padding-left: 30px;
      border-left: 4px solid $sub;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    position: relative;
    margin: 40px 0 20px;
    padding-left: 40px;
    font-size: 18px;
    @include mq(sp) {
      @include fz_vw(14);
    }
    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      display: block;
      content: '';
      height: 4px;
      width: 30px;
      background: $sub;
    }
  }
  h4, h5, h6 {
    margin: 30px 0 10px;
    font-weight: bold;
    &.list-style {
      display: list-item;
      list-style-type: square;
      list-style-position: inside;
    }
  }
}