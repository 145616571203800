@charset "utf-8";

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/
/* サイドバー
-------------------- */

/* タイトル */
.side-title {
  position: relative;
  padding: 20px 30px;
  border-top: 2px solid $sub;
  border-bottom: 1px solid $gray;
  background: $light-main;
  color: $main;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    height: 0;
    width: 100%;
    border-top: 2px solid #fff;
  }
}
.side-subtitle {
  padding: 10px;
  background: $main;
  color: #fff;
}

/* コンテンツ */
.side-box {
  margin-bottom: 30px;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
  border-bottom: 1px solid $gray;
}
.side-about {
  padding: 15px;
  line-height: 1.4;
  &__title {
    margin-bottom: 10px;
    span {
      color: $deep-sub;
      font-size: 18px;
      font-weight: bold;
    }
  }
  &__address {
    margin-bottom: 6px;
  }
  &__tel {
    color: $deep-sub;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
  }
  &__mail {
    margin-bottom: 6px;
    font-family: 'Montserrat', sans-serif;
  }
  &__about {
    margin-bottom: 20px;
    a {
      color: $deep-sub;
      text-decoration: underline;
    }
  }
  &__contact {
    text-align: center;
    a {
      display: block;
      padding: 5px 20px;
    }
  }
}
.side-list {
  margin-bottom: 30px;
  padding: 10px 20px;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
  border-bottom: 1px solid $gray;
  &__item {
    a {
      position: relative;
      display: block;
      padding: 8px 0 8px 16px;
      &::before {
        position: absolute;
        top: 9px;
        left: 0;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left: 8px solid $sub;
      }
    }
  }
  &--2col {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 50%;
      @include mq(sp) {
        width: 33%;
      }
    }
  }
}

/* サイドバナー */
.side-banner {
  margin: 10px 0 30px;
  text-align: center;
  li {
    margin: 0 0 10px;
  }
}


/* ローカルナビ */
.widget {
  margin-bottom: 50px;
}
.widget-local-navi > ul {
  margin: 0 -2px;
  padding: 0 2px 15px;
}
.widget-local-navi > ul > li {
    border-bottom: 1px dotted #CCCCCC;
}
.widget-local-navi li a {
    display: block;
    padding: 16px 10px 16px 16px;
    text-decoration: none;
    color: #333;
}
.widget-local-navi li a:hover {
  color: #CC3300;
}
.widget-local-navi li:last-child {
    border-bottom: none;
}
/* ローカルナビ2層 */
.widget-local-navi ul ul {
  margin-left: 16px;
  margin-bottom: 10px;
}
.widget-local-navi ul ul li a {
  padding: 0px 10px 5px 20px;
  font-size: 13px;
}
.blog-widget li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
  line-height: 1.2;
}
.blog-widget li a {
  color: #1D4C86;
  text-decoration: none;
}
.blog-widget li a:hover {
  color: #00A0D9;
  text-decoration: underline;
}