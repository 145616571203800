@charset "utf-8";

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
/* Footer
-------------------- */

.footer {
  &__info {
    padding: 25px 0 30px;
    background: $light-main;
    &__logo {
      float: left;
      @include mq(sp) {
        float: none;
        text-align: center;
      }
      img {
        width: 300px;
        height: 70px;
      }
    }
    &__sign {
      float: right;
      display: inline-block;
      @include mq(sp) {
        float: none;
        display: block;
      }
      &__address {
        display: inline-block;
        margin-right: 30px;
        padding-top: 4px;
        color: #666666;
        font-size: 13px;
        @include mq(sp) {
          display: block;
          margin-right: 0;
          margin-bottom: 10px;
          text-align: center;
        }
      }
      &__contact {
        display: inline-block;
        vertical-align: middle;
        @include mq(sp) {
          display: block;
          text-align: center;
        }
      }
      &__mail {
        @include mq(sp) {
          display: inline-block;
          margin-right: 10px;
          vertical-align: middle;
        }
        a {
          display: block;
          margin-bottom: 10px;
          padding: 10px;
          border: 1px solid $sub;
          background: $sub url(../img/header/icn_mail_w.svg) 6px center no-repeat;
          background-size: 24px 12px;
          color: #fff;
          font-size: 18px;
          text-align: center;
          text-indent: 1em;
          &:hover {
            background: $light-sub url(../img/header/icn_mail_b.svg) 6px center no-repeat;
            background-size: 24px 12px;
            color: $main;
          }
        }
      }
      &__tel {
        display: block;
        padding-left: 26px;
        background: url(../img/header/icn_tel.svg) left no-repeat;
        background-size: 22px 16px;
        color: $main;
        font-size: 20px;
        @include mq(sp) {
          display: inline-block;
          margin-right: 10px;
          font-size: 18px;
          vertical-align: middle;
        }
      }
    }
  }
  &__line {
    padding: 20px 0;
    background: $sub;
    font-size: 11px;
  }
}
.copyright {
  color: #fff;
  text-align: center;
}

.nav-footer {
  padding: 50px 0;
  border-top: 10px solid #efefef;
  &__inner {
    @include flex();
  }
  &__title {
    margin-bottom: 10px;
    font-size: 17px;
  }
  &__list {
    @include mq(sp) {
      margin-bottom: 20px;
    }
    &__item {
      line-height: 1.5;
      &::before {
        content: "・";
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__01 {
    width: 36%;
    padding-right: 2%;
    @include mq(sp) {
      width: auto;
      padding-right: 0;
    }
  }
  &__02 {
    width: 28%;
    padding-right: 2%;
    @include mq(sp) {
      width: auto;
      padding-right: 0;
      padding-right: 0;
    }
  }
  &__03 {
    width: 16%;
    padding-right: 2%;
    @include mq(sp) {
      width: auto;
      padding-right: 0;
    }
  }
  &__04 {
    width: 20%;
    @include mq(sp) {
      width: auto;
    }
  }
}
.posts-footer {
  @include mq(sp) {
    margin-bottom: 0;
  }
}

.layout-form {
  padding: 30px 0 10px;
  background: #ededed;
  &__inner {
    @include base-wrap--space();
  }
  .form-description {
    margin-bottom: 20px;
  }
  table {
    @include box-sizing(border-box);
    border-top: 1px solid $main;
    tr {
      border-bottom: 1px solid $main;
    }
    th {
      @include box-sizing(border-box);
      padding: 20px;
      color: $main;
      font-size: 16px;
      text-align: left;
      @include mq(sp) {
        @include fz_vw(16);
        display: block;
        width: 100%;
        padding: 15px 10px 0 10px;
      }
    }
    td {
      @include box-sizing(border-box);
      padding: 20px;
      font-size: 15px;
      line-height: 1.5;
      @include mq(sp) {
        @include fz_vw(15);
        display: block;
        width: 100%;
        padding: 10px 10px 15px;
      }
    }
  }
}

