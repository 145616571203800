@charset "utf-8";

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/

/* トップタイトル
-------------------- */
.page-mv {
  background: $light-main;
  &__title {
    @include base-wrap;
    padding: 20px 0 20px 30px;
    color: $main;
    font-size: 40px;
    font-family: $font-round;
    font-weight: 100;
    line-height: 100px;
    @include mq(sp) {
      padding: 10px 0 10px 20px;
      font-size: 20px;
      font-weight: normal;
      line-height: 60px;
    }
  }
}


/* 汎用スタイル
-------------------- */

/* 文章 */
.txt-copy {
  font-size: 24px;
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.main {
  @include mq(sp) {
    margin-left: 10px;
    margin-right: 10px;
  }
  p {
    margin-bottom: 30px;
  }
  .box01 {
    margin-bottom: 30px;
    p {
      margin-bottom: 10px;
    }
  }
}

/* 会社概要Googleマップ */
#map {
  max-width: 100%;
}

/* ブログシステム */
.blog {
  &__box {
    margin-bottom: 50px;
    .date {
      position: relative;
      top: -1px;
      margin-left: 10px;
      color: #888;
      font-size: 14px;
    }
  }
  &__item {
    .date {
      position: relative;
      top: 3px;
      display: block;
      float: left;
      margin-right: 10px;
      color: #888;
      font-size: 14px;
    }
    .title {
      display: block;
      margin-left: 6em;
    }
  }
  &__body {
    margin-bottom: 20px;
  }
  &__more {
    text-align: right;
  }
  #post-detail {
    margin: 50px 0;
  }
}

/* 料金表 */
.price {
  &__name {
  }
  &__table {
    margin-bottom: 30px;
    table {
      border: none;
      tr {
        border: none;
      }
      td {
        padding: 20px 10px;
        border: 1px solid #ddd;
        &:nth-child(1) {
          width: 40%;
          @include mq(sp) {
            width: auto;
            border-bottom: none;
          }
        }
        &:nth-child(2) {
          width: 40%;
          @include mq(sp) {
            width: auto;
            border-bottom: none;
          }
        }
        &:nth-child(3) {
          width: 20%;
          @include mq(sp) {
            width: auto;
          }
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}