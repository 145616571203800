@charset "utf-8";

html {
  width: 100%;
}
body {
  width: 100%;
	margin: 0;
	padding: 0;
	font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	font-style: normal;
	font-weight: normal;
	color:$black;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	font-size: $base-fs;
  overflow-x: hidden;
  @include mq(tab) {
    width: 1024px;
  }
}

a {
	color: $black;
  text-decoration: none;
  transition: .2s;
  &:hover {
    transition: .2s;
  }
  &:visited {
  }
}

img {
  max-width: 100%;
  height: auto;
}

a:hover img {
    opacity: 0.9;
}