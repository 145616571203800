@charset "utf-8";

$black: #333;
$gray: #E3E7ED;/* 薄いグレー */
$main: #251E0D;/* 濃い茶 */
$sub: #9AAA27;/* 黄緑 */
$light-main: #F4EEE9;/* ベージュ */
$light-sub: #D6DF20;/* 明るい黄緑 */
$deep-sub: #565B17;/* 濃い緑 */

$base-lh: 1.5;
$base-fs: 15px;

$mincho: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font-times: 'Times New Roman','Luchida Grande';
$font-round: "M PLUS Rounded 1c";
