@charset "utf-8";

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/

/* ボタンスタイル */
.button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 2px;
  background: $sub;
  color: #fff;
  font-size:15px;
  font-weight:normal;
  text-decoration: none;
  &:hover {
    color: $main;
  }
  &:active {
    position:relative;
    top:1px;
  }
}
/* フォーム送信完了ボタン */
.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 150px;
  padding: 15px 20px;
  border: none;
  background: $sub;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  &:hover {
    color: $main;
  }
  &:active {
    color: $main;
  }
  &:visited {
    color: #fff;
  }
}
/* エラーページボタン */
.link {
  a {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: 150px;
    padding: 15px 20px;
    border: none;
    background: $sub;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
  }
}