@charset "utf-8";

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/

/* MainImage
-------------------- */
.pc-mainimage {
  position: relative;
  width: 100%;
  min-width: 1000px;
  height: 400px;
  margin-bottom: 10px;
  background: $light-main;
  @include mq(sp) {
    display: none;
  }
  &__image {
    position: relative;
    left: 50%;
    top: 0;
    width: 1000px;
    margin-left: -500px;
  }
}
.sp-mainimage {
  display: none;
  @include mq(sp) {
    display: block;
    margin-bottom: 10px;
    & img {
      width: 100%;
    }
  }
}
.mainimage {
  &__note {
    @include base-wrap--space();
    margin-bottom: 50px;
    &__text {
      line-height: 1.5;
    }
  }
}




/* Main
-------------------- */

.feed-news {
  margin-bottom: 70px;
  .title-header {
    position: relative;
    .link-more {
      position: absolute;
      top: 2px;
      right: 0;
      a {
        position: relative;
        display: block;
        padding: 0 25px;
        color: $main;
        text-decoration: none;
        line-height: 65px;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          content: '';
          width: 20px;
          height: 20px;
          margin-top: -10px;
          border-radius: 50%;
          background: $sub;
        }
        &::after {
          position: absolute;
          top: 50%;
          left: 6px;
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          margin-top: -3px;
          border-right: 1px solid #fff;
          border-top: 1px solid #fff;
          transform: rotate(45deg);
        }
      }
    }
  }
  ul {
    li {
      @include clearfix;
      padding: 15px 0;
      border-bottom: 1px dashed #999999;
      .date {
        float: left;
        display: block;
        color: #999999;
        @include mq(sp) {
          float: none;
        }
      }
      .title {
        display: block;
        margin-left: 7em;
        font-size: 15px;
        @include mq(sp) {
          margin-left: 0;
        }
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &--default {
          margin-left: 0;
        }
      }
    }
  }
}

.service-price {
  span {
    margin: 0 3px 0 10px;
    color: #f00;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: bold;
  }
}
