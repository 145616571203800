@charset "utf-8";

.contents {
  @include base-wrap--space;
  @include flex();
  margin-bottom: 50px;
  @include mq(sp) {
    margin-top: 30px;
  }
}
.main {
  width: 680px;
  line-height: $base-lh;
  font-size: $base-fs;
  @include mq(sp) {
    width: auto;
    margin-bottom: 50px;
  }
}
.side {
  width: 280px;
  @include mq(sp) {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.base-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.main-wrap {
  width: 680px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 680px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.screen-reader-text {
  display: none;
}
