
/*--------------------------------------------------------
  ぱんくず
----------------------------------------------------------*/

.breadcrumbs {
  @include base-wrap--space;
    margin: 20px auto 50px;
    color: #333;
    font-size: 11px;
    @include mq(sp) {
      width: auto;
      margin: 10px 10px 20px;
    }
  a {
    display: inline-block;
    margin: 0 5px;
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
  .separator01 {
    color: #000;
  }
  strong {
    display: inline-block;
    margin: 0 5px;
    font-weight: normal;
  }
}
.breadcrumbs {
  @include mq(sp) {
    display: none;
  }
}