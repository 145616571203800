@charset "utf-8";

/*--------------------------------------------------------
  ページネーション
----------------------------------------------------------*/

.pagination {
  margin: 60px 0;
  text-align: center;
  font-size: 14px;
}
.pagination span.disabled,
.pagination span.prev a,
.pagination span.next a {
  margin: 0 20px;
  color: $main;
}
.pagination span.current,
.pagination span.number a {
  margin: 0 4px;
  padding: 7px 12px;
  background: #dedede;
  color: #fff;
  text-decoration: none !important;
}
.pagination span.current,
.pagination span.number a:hover {
  background: $main;
}
.pagination a:active {
  background: $main;
}


/*--------------------------------------------------------
  コンテンツナビ
----------------------------------------------------------*/

.contents-navi {
  margin: 50px 0;
  text-align: center;
  a {
    display: inline-block;
    padding: 11px 14px;
    color: #000;
    &.prev-link,
    &.next-link {
      background: #ddd;

    }
    &:hover {
      color: #333;
    }
    &:hover.prev-link,
    &:hover.next-link {
      background: #eee;
    }
  }
}
