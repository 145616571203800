@charset "utf-8";

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/

.header {
  margin-bottom: 20px;
  @include mq(sp) {
    height: auto;
    margin-bottom: 20px;
  }
  &__copy {
    margin: 5px 0;
    font-size: 12px;
    @include mq(sp) {
      display: none;
    }
  }
  &__logo {
    float: left;
    display: inline-block;
    padding: 10px 0 0 0;
    @include mq(sp) {
      float: none;
      display: block;
      padding: 15px 0 0;
      text-align: center;
    }
    &__title {
      @include mq(sp) {
        float: none;
        margin-left: 10px;
        text-align: left;
      }
      a {
        img {
          width: 330px;
          height: 70px;
          @include mq(sp) {
            width: 280px;
            height: 50px;
          }
        }
      }
    }
  }
  &__info {
    float: right;
    display: inline-block;
    padding: 12px 0 0 0;
    @include mq(sp) {
      display: none;
    }
  }
  &__address {
    display: inline-block;
    margin-right: 40px;
    padding-top: 4px;
    color: #666666;
    font-size: 13px;
    vertical-align: middle;
  }
  &__contact {
    display: inline-block;
    vertical-align: middle;
  }
  &__mail {
    a {
      display: block;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid $sub;
      background: $sub url(../img/header/icn_mail_w.svg) 6px center no-repeat;
      background-size: 24px 12px;
      color: #fff;
      font-size: 18px;
      text-align: center;
      text-indent: 1em;
      &:hover {
        background: $light-sub url(../img/header/icn_mail_b.svg) 6px center no-repeat;
        background-size: 24px 12px;
        color: $main;
      }
    }
  }
  &__tel {
    display: block;
    padding-left: 26px;
    background: url(../img/header/icn_tel.svg) left no-repeat;
    background-size: 22px 16px;
    color: $main;
    font-size: 20px;
  }
}
#FixNavigator {
  background: $sub;
}
.nav-global {
  z-index: 1000;
  @include mq(sp) {
    position: static;
    display: none;
    width: auto;
  }
  &__list {
    display: table;
    width: 100%;
    @include mq(sp) {
      display: block;
      height: auto;
      border-left: none;
      border-top: 1px solid #999;
    }
    &__item {
      position: relative;
      display: table-cell;
      @include mq(sp) {
        display: block;
        border-bottom: 1px solid #fff;
      }
      & > a {
        @include box-sizing(border-box);
        display: block;
        padding: 0 30px;
        border-left: 1px solid $light-sub;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 60px;
        text-decoration: none;
        @include mq(sp) {
          width: auto;
          height: auto;
          padding: 15px 20px;
          border-right: none;
          border-bottom: none;
          background: $sub url(../img/sp/icn_arrow_01.png) 95% center no-repeat;
          background-size: 6px 12px;
          color: #fff;
          line-height: 1.5;
          text-align: left;
        }
        &:hover {
          background: $light-sub;
          color: $main;
        }
        &:active {
          background: $light-sub;
          color: $main;
        }
      }
      &:last-child {
        a {
          padding: auto 38px;
          border-right: 1px solid $light-sub;
        }
      }
    }
  }
}
@include mq(pc) {
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
  }
  .fixed .mb60 {
    margin-bottom: 0 !important;
  }
}