@charset "utf-8";

/*--------------------------------------------------------
  フォーム
----------------------------------------------------------*/

.form-table {
  tr {
    @include mq(sp) {
      margin-bottom: 1.5em;
    }
    th {
      width: 30%;
      vertical-align: top;
      @include mq(sp) {
        display: block;
        width: auto;
        padding: 5px 10px;
        background: $light-main;
        color: $main;
      }
    }
    td {
      @include mq(sp) {
        display: block;
        width: auto;
      }
    }
  }
  input[type=text] {
    @include box-sizing(border-box);
    width: 100%;
    margin: 0 10px 4px 0;
    padding: 8px;
    border: 1px solid $sub;
    font-size: 16px;
    @include mq(sp) {
    }
  }
  .checkbox {
    font-size: 16px;
  }
  textarea {
    @include box-sizing(border-box);
    width: 100%;
    padding: 8px;
    border: 1px solid $sub;
    font-size: 16px;
    @include mq(sp) {
    }
  }
  select {
    @include box-sizing(border-box);
    position: relative;
    margin: 0 0 4px 0;
    border: 1px solid $sub;
    font-size: 16px;
  }
  a {
    color: $deep-sub;
    text-decoration: underline;
  }
  ul {
    li {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }
}
.form-s {
  width: 24% !important;
}
.form-m {
  width: 50% !important;
}
.form-l {
  width: 90%;
  @include mq(sp) {
    width: 100%;
  }
}
.required {
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 5px;
  padding: 1px 4px;
  border-radius: 4px;
  background: #c00;
  color: #fff;
  font-size: 12px;
}
.submit {
  margin: 30px 0;
  text-align: center;
  .form-submit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: 150px;
    padding: 15px 20px;
    border: none;
    background: $sub;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
  }
}
.mail-description {
  margin-bottom: 10px;
}
.mail-before-attachment {
  margin-right: 10px;
}
.mail-after-attachment {
  margin-left: 10px;
  margin-right: 10px;
}
#flashMessage {
  margin-bottom: 10px;
}
.alert-message {
  color: #c00;
}
#FieldMessageZip {
  margin-right: 15px;
}
.form-back {
  margin-top: 50px;
  a {
  }
}
